<template>
	<router-view />
</template>

<script>
	export default {
		name: "SiteIndex"
	};
</script>

<style></style>
